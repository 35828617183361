import { KeyboardEvent } from 'react';
import { z } from 'zod';

// regex for a string containing numbers or empty string
const NumberStringSchema = z.string().regex(/^\d*$/);
// const FloatStringSchema = z.string().regex(/^[0-9]*\.[0-9]{2}$/); // allows 1 decimal point and 2 numbers after decimal
const FloatStringSchema = z.string().regex(/^\d*\.?\d*$/); // allows multiple decimal points and many numbers after decimal

const allowNumberStringOnly = (value: string, type?: 'int' | 'float') => {
  // allows numbers or empty string to pass

  const schemaToUse = type === 'float' ? FloatStringSchema : NumberStringSchema;

  const userInput = schemaToUse.safeParse(value);

  return userInput.success ? userInput.data : '';
};

const allowFloatStringOnly = (value: string) => {
  // allows numbers or empty string to pass

  const userInput = FloatStringSchema.safeParse(value);

  return {
    data: userInput.success ? userInput.data : '',
    success: userInput.success,
  };
};

const convertStringToNumber = (value: string, type?: 'int' | 'float') => {
  const schemaToUse = type === 'float' ? FloatStringSchema : NumberStringSchema;

  const userInput = schemaToUse.safeParse(value); // empty string will pass regex

  return userInput.success && value !== ''
    ? type === 'float'
      ? Number.parseFloat(userInput.data)
      : Number.parseInt(userInput.data)
    : 0;
};

const convertNumberToTextInput = (value: number) => {
  return value === 0 ? '' : value.toFixed(0);
};

const handleIntegerInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
  // might want to add "-" for negative numbers
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
  if (!/^[\d.]$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

const handleFloatInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
  // might want to add "-" for negative numbers
  const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.'];
  if (!/^[\d.]$/.test(e.key) && !allowedKeys.includes(e.key)) {
    e.preventDefault();
  }
};

export {
  allowNumberStringOnly,
  convertStringToNumber,
  convertNumberToTextInput,
  allowFloatStringOnly,
  NumberStringSchema,
  FloatStringSchema,
  handleIntegerInputKeyDown,
  handleFloatInputKeyDown,
};
