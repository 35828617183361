import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CustomCellEditorProps } from '@ag-grid-community/react';
import { IconCheck, IconInfoCircle } from '@tabler/icons-react';
import { useWatch } from 'react-hook-form';
import { NumberInput, Text, UnstyledButton } from '@ui/core';
import { cx } from '@ui/utils';
import { useProductDataGridFormContext } from '@components/ProductDataGrid/useProductDataGridForm';
import { convertStringToNumber } from '@utils/numberInput';
import { type RowData } from '../../types';

const StoreQuantityCellEditor = memo(
  ({
    eventKey,
    stopEditing,
    data,
    node,
    onValueChange,
    addressId,
  }: CustomCellEditorProps<RowData> & { addressId: string }) => {
    const [quantity, setQuantity] = useState<string>(() => {
      if (!eventKey) return '';
      const num = Number.parseInt(eventKey, 10);
      if (Number.isNaN(num)) return '';
      if (num >= 0 && num <= 9) {
        return `${num}`;
      }
      return '';
    });
    const refContainer = useRef<HTMLInputElement>(null);
    const productDataGridForm = useProductDataGridFormContext();
    const { getValues } = productDataGridForm;
    const primaryVariant = getValues(
      `brands.brand_${data?.brandId}.primaryVariants.${data?.parentUniqueRowId}`
    );

    const aggregateQty: number =
      data.type === 'secondaryVariantRow'
        ? Object.keys(data.storeToQuantityMap).reduce((acc, key) => {
            if (key === addressId) return acc;
            return acc + data.storeToQuantityMap[key];
          }, 0) + convertStringToNumber(quantity)
        : convertStringToNumber(quantity);

    useEffect(() => {
      refContainer.current?.focus();
    }, []);

    const stock = data.type === 'secondaryVariantRow' ? data.stock : 0;

    const onChange = useCallback((value: number | string) => {
      setQuantity(`${value}`);
    }, []);

    const skipStockCheck = useWatch({
      name: `brands.brand_${data.brandId}.primaryVariants.${node.parent?.data?.uniqueRowId}.skipStockCheck`,
    });

    const isError = skipStockCheck === false && aggregateQty > (stock ?? 0);

    const onSave = useCallback(() => {
      if (!isError) {
        onValueChange(quantity === '' ? 0 : convertStringToNumber(quantity));
        stopEditing();
      }
    }, [quantity, stopEditing, onValueChange, isError]);

    const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const val = refContainer.current?.value.replace(/,/g, '') ?? '';
        const isError = skipStockCheck === false && convertStringToNumber(val) > (stock ?? 0);
        if (!isError) {
          onValueChange(val === '' ? 0 : convertStringToNumber(val));
          stopEditing();
          return;
        }
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const pricingOptionSelected = useMemo(() => {
      // must select atlease one collection if indent only
      if (!primaryVariant) return true;
      if (primaryVariant.indentOnly) {
        const collection = primaryVariant.collections.find(
          option => option.id === primaryVariant.appliedCollectionId
        );
        return !!collection?.id;
      }
      return true;
    }, [primaryVariant]);

    return (
      <div className="flex flex-col border border-andisor-blue bg-white p-2">
        {!pricingOptionSelected ? (
          <Text className="mb-2 flex w-52 items-center gap-2 text-xs">
            <IconInfoCircle size={16} stroke={1.5} className="shrink-0" />
            Please select a pricing option to edit quantity
          </Text>
        ) : null}
        <div className="flex items-center gap-2">
          <NumberInput
            ref={refContainer}
            onKeyDownCapture={onEnterPress}
            min={0}
            size="xs"
            allowDecimal={false}
            value={`${quantity}`}
            onChange={onChange}
            classNames={{
              input: cx(
                'rounded-none border-0 border-b text-right',
                isError && 'border-b-andisor-red'
              ),
            }}
            isAllowed={inputVal => inputVal.value !== '-'}
            error={isError && `Exceeds stock (${stock})`}
            valueIsNumericString
            disabled={!pricingOptionSelected}
          />
          <UnstyledButton
            onClick={onSave}
            className={cx(
              'px-1.5 text-andisor-green',
              isError && 'cursor-not-allowed text-andisor-gray-400'
            )}
            tabIndex={-1}
            disabled={isError || !pricingOptionSelected}
          >
            <IconCheck size={18} />
          </UnstyledButton>
        </div>
      </div>
    );
  }
);

export default StoreQuantityCellEditor;
