import { cx } from '@ui/utils';

const ColorPill = ({
  color,
  className = 'h-4 w-4',
}: {
  color: string | null | undefined;
  className?: string;
}) => {
  const showMultiColor = color === null;
  return (
    <>
      {!showMultiColor ? (
        <div
          className={cx('shrink-0 rounded-full border border-solid border-white', className)}
          style={{
            backgroundColor: color,
            boxShadow: `${
              color === '#ffffff'
                ? '0 3px 10px rgba(65.75, 65.75, 65.75, 0.20)'
                : '0 4px 6px rgba(63.75, 63.75, 63.75, 0.10)'
            }`,
          }}
        />
      ) : (
        <img
          src="/assets/images/multi_color_wheel.png"
          alt="Multi color"
          className={cx('shrink-0 rounded-full border border-solid border-white', className)}
          style={{
            boxShadow: '0 4px 6px rgba(63.75, 63.75, 63.75, 0.10)',
          }}
        />
      )}
    </>
  );
};

export default ColorPill;
