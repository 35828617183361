import React, { createContext, useContext } from 'react';
import { cx } from '@ui/utils';
import { RowWrapper } from './RowWrapper';

// context for global tableClassnames
type TableContextTypes = TableClassNames;
const TableContext = createContext<TableContextTypes>({});
export const useTableContext = () => useContext(TableContext);

interface TableClassNames {
  tableClassName?: string;
  tableHeadClassName?: string;
  tableHeadDataClassName?: string;
  tableRowClassName?: (({ rowIndex }: { rowIndex: number }) => string) | string;
  tableDataClassName?: string;
  tableBodyClassName?: string;
  tableContainerClassName?: string;
  usingCustomScrollbar?: boolean;
}

type TableProps<TData extends object> = {
  data: TData[];
  row: ({ row, rowIndex }: { row: TData; rowIndex: number }) => React.ReactNode;
  head: React.ReactNode;
} & TableClassNames;

const Table = <TData extends object>({
  data,
  head,
  row,
  tableBodyClassName = '',
  tableClassName = '',
  tableDataClassName = '',
  tableHeadClassName = '',
  tableHeadDataClassName = '',
  tableRowClassName = '',
  tableContainerClassName = '',
  usingCustomScrollbar = false,
}: TableProps<TData>) => {
  return (
    <TableContext.Provider
      value={{
        tableClassName,

        tableHeadClassName,
        tableHeadDataClassName,

        tableBodyClassName,
        tableRowClassName,
        tableDataClassName,
      }}
    >
      <div
        id="table-wrapper"
        className={cx(usingCustomScrollbar ? 'w-fit' : 'overflow-auto', tableClassName)}
      >
        <div
          id="table"
          className={cx(
            `flex w-full table-auto flex-col text-sm tracking-wide`,
            tableContainerClassName
          )}
        >
          {head}
          <div id="tbody" className={`flex flex-col ${tableBodyClassName}`}>
            {data?.map((rowData, rowIndex) => {
              return (
                <RowWrapper
                  // passing rowIndex down context, so no prop drilling
                  rowIndex={rowIndex}
                  key={`tr-${rowIndex}`}
                >
                  {row({ row: rowData, rowIndex })}
                </RowWrapper>
              );
            })}
          </div>
        </div>
      </div>
    </TableContext.Provider>
  );
};

export default Table;
