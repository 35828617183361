import { ColDef, EditableCallbackParams, ValueGetterParams } from '@ag-grid-community/core';
import { StoresType } from '@api/buyers/order-form';
import { convertDateToFormattedString } from '@utils/date';
import StoreQuantityCell from './cells/StoreQuantityCell/StoreQuantityCell';
import StoreQuantityCellEditor from './cells/StoreQuantityCell/StoreQuantityCellEditor';
import { RowData } from './types';
import { type ProductDataGridFormType } from './useProductDataGridForm';

export const flattenBrandsObject = (obj: ProductDataGridFormType['brands']) => {
  const flattened: ProductDataGridFormType['brands'][string]['primaryVariants'] = {};
  for (const brandKey in obj) {
    const { primaryVariants } = obj[brandKey];
    for (const pvKey in primaryVariants) {
      flattened[pvKey] = primaryVariants[pvKey];
    }
  }
  return flattened;
};

export const getEditedValues = (
  defaultObject: ProductDataGridFormType['brands'][string]['primaryVariants'],
  defaultObjectWithEdits: ProductDataGridFormType['brands'][string]['primaryVariants']
) => {
  const updateItemVariants: {
    [key: string]: {
      itemVariantId: number;
      appliedCollectionId?: number | null;
      requestedFor?: string | null;
    };
  } = {};
  const addItemSKUDetails: {
    [key: string]: {
      sku: string;
      productId: string;
      variantId: string;
      itemVariantId: number;
      quantity: number;
    };
  } = {};
  const updateItemSKUDetails: {
    [key: string]: {
      itemSKUDetailId: number;
      quantity: number;
    };
  } = {};

  let totalValidPrimaryVariants = Object.keys(defaultObject).length;
  const primaryVariantsForProductTotalMap: { [key: string]: number } = {};
  const removedItemVariantIdsForProductsMap: { [key: string]: number[] } = {};

  for (const pkey in defaultObjectWithEdits) {
    const editedItem = defaultObjectWithEdits[pkey];
    const defaultItem = defaultObject[pkey];

    if (!primaryVariantsForProductTotalMap[editedItem.parentUniqueRowId]) {
      primaryVariantsForProductTotalMap[editedItem.parentUniqueRowId] = 0;
    }
    primaryVariantsForProductTotalMap[editedItem.parentUniqueRowId] += 1;

    if (editedItem.rowStatus === 'removed') {
      totalValidPrimaryVariants -= 1;
      if (!removedItemVariantIdsForProductsMap[editedItem.parentUniqueRowId]) {
        removedItemVariantIdsForProductsMap[editedItem.parentUniqueRowId] = [];
      }
      removedItemVariantIdsForProductsMap[editedItem.parentUniqueRowId].push(
        editedItem.itemVariantId
      );
      continue;
    }

    if (editedItem.appliedCollectionId !== defaultItem.appliedCollectionId) {
      if (!updateItemVariants[editedItem.uniqueRowId]) {
        updateItemVariants[editedItem.uniqueRowId] = {
          itemVariantId: editedItem.itemVariantId,
        };
      }
      updateItemVariants[editedItem.uniqueRowId].appliedCollectionId =
        editedItem.appliedCollectionId;
    }

    if (editedItem.requestedFor !== defaultItem.requestedFor) {
      if (!updateItemVariants[editedItem.uniqueRowId]) {
        updateItemVariants[editedItem.uniqueRowId] = {
          itemVariantId: editedItem.itemVariantId,
        };
      }
      updateItemVariants[editedItem.uniqueRowId].requestedFor = editedItem.requestedFor
        ? convertDateToFormattedString(editedItem.requestedFor)
        : null;
    }

    const editedSecondaryVariants = editedItem.secondaryVariants;
    const defaultSecondaryVariants = defaultItem.secondaryVariants;

    if (editedSecondaryVariants) {
      Object.keys(editedSecondaryVariants).forEach(skey => {
        const secEditedVal = editedSecondaryVariants[skey];
        const secDefaultVal = defaultSecondaryVariants[skey];

        if (secEditedVal.quantity !== secDefaultVal.quantity) {
          if (secEditedVal.itemSKUDetailId === null) {
            addItemSKUDetails[secEditedVal.uniqueRowId] = {
              sku: secEditedVal.sku,
              productId: secEditedVal.productId,
              variantId: secEditedVal.variantId,
              itemVariantId: secEditedVal.itemVariantId,
              quantity: secEditedVal.quantity,
            };
          } else {
            updateItemSKUDetails[secEditedVal.itemSKUDetailId] = {
              itemSKUDetailId: secEditedVal.itemSKUDetailId,
              quantity: secEditedVal.quantity,
            };
          }
        }
      });
    }
  }

  const removeItemIds: number[] = [];
  const removeItemVariantIds: number[] = [];

  for (const pkey in primaryVariantsForProductTotalMap) {
    const total = primaryVariantsForProductTotalMap[pkey];
    const removedIds = removedItemVariantIdsForProductsMap[pkey];

    if (removedIds) {
      // if total matches the number of removed items, then we can remove the product itself
      if (total === removedIds.length) {
        removeItemIds.push(Number(pkey));
      } else {
        removeItemVariantIds.push(...removedIds);
      }
    }
  }

  return {
    updateItemVariants: Object.values(updateItemVariants),
    addItemSKUDetails: Object.values(addItemSKUDetails),
    updateItemSKUDetails: Object.values(updateItemSKUDetails),
    removeItemIds,
    removeItemVariantIds,
    totalValidPrimaryVariants: totalValidPrimaryVariants,
  };
};

export const getEditedValuesOrderForm = (
  defaultObject: ProductDataGridFormType['brands'][string]['primaryVariants'],
  defaultObjectWithEdits: ProductDataGridFormType['brands'][string]['primaryVariants'],
  stores: StoresType
) => {
  const updateItemVariants: {
    [key: string]: {
      itemVariantId: number;
      appliedCollectionId?: number | null;
      requestedFor?: string | null;
    };
  } = {};
  const addItemSKUDetails: {
    sku: string;
    productId: string;
    variantId: string;
    itemVariantId: number;
    quantity: number;
    orderFormStoreId: number;
  }[] = [];

  const updateItemSKUDetails: {
    itemSKUDetailId: number;
    quantity: number;
    orderFormStoreId: number;
  }[] = [];

  let totalValidPrimaryVariants = Object.keys(defaultObject).length;
  const primaryVariantsForProductTotalMap: { [key: string]: number } = {};
  const removedItemVariantIdsForProductsMap: { [key: string]: number[] } = {};

  for (const pkey in defaultObjectWithEdits) {
    const editedItem = defaultObjectWithEdits[pkey];
    const defaultItem = defaultObject[pkey];

    if (!primaryVariantsForProductTotalMap[editedItem.parentUniqueRowId]) {
      primaryVariantsForProductTotalMap[editedItem.parentUniqueRowId] = 0;
    }
    primaryVariantsForProductTotalMap[editedItem.parentUniqueRowId] += 1;

    if (editedItem.rowStatus === 'removed') {
      totalValidPrimaryVariants -= 1;
      if (!removedItemVariantIdsForProductsMap[editedItem.parentUniqueRowId]) {
        removedItemVariantIdsForProductsMap[editedItem.parentUniqueRowId] = [];
      }
      removedItemVariantIdsForProductsMap[editedItem.parentUniqueRowId].push(
        editedItem.itemVariantId
      );
      continue;
    }

    if (editedItem.appliedCollectionId !== defaultItem.appliedCollectionId) {
      if (!updateItemVariants[editedItem.uniqueRowId]) {
        updateItemVariants[editedItem.uniqueRowId] = {
          itemVariantId: editedItem.itemVariantId,
        };
      }
      updateItemVariants[editedItem.uniqueRowId].appliedCollectionId =
        editedItem.appliedCollectionId;
    }

    if (editedItem.requestedFor !== defaultItem.requestedFor) {
      if (!updateItemVariants[editedItem.uniqueRowId]) {
        updateItemVariants[editedItem.uniqueRowId] = {
          itemVariantId: editedItem.itemVariantId,
        };
      }
      updateItemVariants[editedItem.uniqueRowId].requestedFor = editedItem.requestedFor
        ? convertDateToFormattedString(editedItem.requestedFor)
        : null;
    }

    const editedSecondaryVariants = editedItem.secondaryVariants;
    const defaultSecondaryVariants = defaultItem.secondaryVariants;

    if (editedSecondaryVariants) {
      Object.keys(editedSecondaryVariants).forEach(skey => {
        const secEditedVal = editedSecondaryVariants[skey];
        const secDefaultVal = defaultSecondaryVariants[skey];
        if (
          JSON.stringify(secEditedVal.storeToQuantityMap) !==
          JSON.stringify(secDefaultVal.storeToQuantityMap)
        ) {
          Object.keys(secEditedVal.storeToQuantityMap).forEach(storeKey => {
            const quantity = secEditedVal.storeToQuantityMap[storeKey];
            const addingNewStore = secDefaultVal.storeToQuantityMap[storeKey] === undefined;
            const orderFormStoreId =
              stores.find(store => store.id === Number(storeKey))?.orderFormStoreId || 0;
            if (secEditedVal.itemSKUDetailId === null || addingNewStore) {
              // add
              addItemSKUDetails.push({
                sku: secEditedVal.sku,
                productId: secEditedVal.productId,
                variantId: secEditedVal.variantId,
                itemVariantId: secEditedVal.itemVariantId,
                quantity,
                orderFormStoreId,
              });
            } else {
              // update
              updateItemSKUDetails.push({
                itemSKUDetailId: secEditedVal.itemSKUDetailId,
                quantity,
                orderFormStoreId,
              });
            }
          });
        }
      });
    }
  }

  const removeItemIds: number[] = [];
  const removeItemVariantIds: number[] = [];

  for (const pkey in primaryVariantsForProductTotalMap) {
    const total = primaryVariantsForProductTotalMap[pkey];
    const removedIds = removedItemVariantIdsForProductsMap[pkey];

    if (removedIds) {
      // if total matches the number of removed items, then we can remove the product itself
      if (total === removedIds.length) {
        removeItemIds.push(Number(pkey));
      } else {
        removeItemVariantIds.push(...removedIds);
      }
    }
  }

  return {
    updateItemVariants: Object.values(updateItemVariants),
    addItemSKUDetails,
    updateItemSKUDetails,
    removeItemIds,
    removeItemVariantIds,
    totalValidPrimaryVariants: totalValidPrimaryVariants,
  };
};

export const getClosestDate = (date: Date, dates: Date[]) => {
  return dates.reduce((prev, curr) => {
    const prevDiff = Math.abs(prev.getTime() - date.getTime());
    const currDiff = Math.abs(curr.getTime() - date.getTime());
    return currDiff < prevDiff ? curr : prev;
  });
};

export const shouldExcludeDate = (date: Date) => {
  const isLeapYear = (year: number) => {
    // leap years are divisible by 4 but not by 100, or they are divisible by 400
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // check if the date is on the 15th or 30th or (28th, or 29th (for leap years) for Feb)
  return !(
    day === 15 ||
    day === 30 ||
    (month === 2 && ((day === 28 && !isLeapYear(year)) || (day === 29 && isLeapYear(year))))
  );
};

export const getStoreQtyColumns = (
  allColumnDefs: ColDef<RowData>[],
  allAddressIds: string[],
  selectedAddresses: { label: string; value: string }[]
): ColDef<RowData>[] => {
  const addressColumnDefs: ColDef<RowData>[] = [];
  // list of non-store columns
  const nonAddressColumnDefs: ColDef<RowData>[] = allColumnDefs.filter(
    column => !allAddressIds.includes(column.cellEditorParams?.addressId as string)
  );

  // create new columns for the selected stores
  selectedAddresses?.forEach(selectedAddress => {
    if (!selectedAddress) return;
    const newColumn: ColDef<RowData> = {
      colId: `storeToQuantityMap.${selectedAddress.value}`,
      field: `storeToQuantityMap.${selectedAddress.value}`,
      headerName: selectedAddress.label,
      aggFunc: 'sum',
      cellDataType: 'number',
      cellRenderer: StoreQuantityCell,
      cellEditor: StoreQuantityCellEditor,
      suppressPaste: false, // enable paste
      suppressFillHandle: false, // enable fill
      cellStyle: { padding: 0 },
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
      minWidth: 90,
      maxWidth: 90,
      editable: (params: EditableCallbackParams<RowData>) => {
        // only allow editing at the sv level
        return params.data?.type === 'secondaryVariantRow';
      },
      cellEditorParams: { addressId: selectedAddress.value },
      valueGetter: (params: ValueGetterParams<RowData>) => {
        return params.data?.type === 'secondaryVariantRow'
          ? params.data?.storeToQuantityMap[selectedAddress.value] || 0
          : 0;
      },
      headerTooltip: selectedAddress.label,
      useValueFormatterForExport: false,
    };
    addressColumnDefs.push(newColumn);
  });

  return [...nonAddressColumnDefs, ...addressColumnDefs];
};

export const getMaskedStock = (stock: number | string): string => {
  return Number(stock) > 10 ? '10+' : String(stock);
};
