import React from 'react';
import { cx } from '@ui/utils';
import { useTableContext } from './Table';

interface THProps {
  children?: string | React.ReactNode;
  className?: string;
}

const TH = ({ children, className }: THProps) => {
  // getting the classNames for the TD and TH via the global Table context
  const { tableHeadDataClassName } = useTableContext();

  // each td has some default styles and can be added to
  return (
    <div id="th" className={cx('flex whitespace-nowrap', tableHeadDataClassName, className)}>
      {children}
    </div>
  );
};

export { TH };
