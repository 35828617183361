import React from 'react';
import { cx } from '@ui/utils';
import { useTableContext } from './Table';

interface TDProps {
  children?: string | React.ReactNode;
  className?: string;
}

const TD = ({ children, className = '' }: TDProps) => {
  // getting the classNames for the TD and TH via the global Table context
  const { tableDataClassName } = useTableContext();

  // each td has some default styles and can be added to
  return (
    <div id="td" className={cx('flex whitespace-nowrap', tableDataClassName, className)}>
      {children}
    </div>
  );
};

export { TD };
