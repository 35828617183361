import React, { useState } from 'react';
import { useTableRowContext } from './RowWrapper';
import { useTableContext } from './Table';

interface TRProps {
  children: React.ReactNode;
  className?: string;
  type?: 'trow' | 'thead';
}

const useTableRow = (initialExpandedState: boolean = false) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(initialExpandedState);
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);
  const { getTableRowClassName, rowIndex } = useTableRowContext();

  return {
    isEditing,
    setIsEditing,
    isExpanded,
    toggleIsExpanded,
    setIsExpanded,
    rowIndex,
    getTableRowClassName,
  };
};

const TR = ({ children, className, type = 'trow' }: TRProps) => {
  // ever row has access to these states via render props
  // the root level row's isExpanded state however comes from RowWrapperContext

  const { getTableRowClassName } = useTableRowContext();
  const { tableHeadClassName } = useTableContext();

  return (
    <>
      <div
        id={type}
        // add these classes for borders on x axis for rows
        // divide-x divide-blue-500
        className={`${type === 'thead' ? `flex ${tableHeadClassName}` : getTableRowClassName()} ${
          className ?? ''
        }`}
      >
        {children}
      </div>
    </>
  );
};

export { TR, useTableRow };
