import React, { createContext, useContext } from 'react';
import { useTableContext } from './Table';

type TableRowContextTypes = {
  rowIndex: number;
};
const TableRowContext = createContext<TableRowContextTypes>({
  rowIndex: 0,
});
const useTableRowContext = () => {
  // context for the row Index and a fucntion that returns the rowClassName
  const { tableRowClassName } = useTableContext();
  const { rowIndex } = useContext(TableRowContext);

  const getTableRowClassName = () => {
    return `flex ${
      tableRowClassName === undefined
        ? ''
        : typeof tableRowClassName === 'function'
          ? // passing rowIndex to style based on rowNumber
            tableRowClassName({ rowIndex })
          : tableRowClassName
    }`;
  };

  return {
    getTableRowClassName,
    rowIndex,
  };
};

interface RowWrapperProps {
  rowIndex: number;
  children: React.ReactNode;
}
const RowWrapper = ({ rowIndex, children }: RowWrapperProps) => {
  // the state for if the root level row isExpanded or not
  // passing it down to children via render props
  return <TableRowContext.Provider value={{ rowIndex }}>{children}</TableRowContext.Provider>;
};

export { RowWrapper, useTableRowContext };
