import {
  compareDesc,
  eachDayOfInterval,
  endOfDay,
  getDate,
  getDaysInMonth,
  isLastDayOfMonth,
  isWithinInterval,
  startOfDay,
  startOfToday,
} from 'date-fns';
import { convertStringToNumber } from '@utils/numberInput';
import { GST_PERCENT } from '@constants';

export const convertNumberToPriceFormat = (
  myNumber: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2
) => {
  if (Number.isNaN(myNumber)) return undefined;

  return myNumber.toLocaleString('en-AU', {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export type getWHSProps = {
  RRP: number; // float of price 10.23 = $10.23
  discount: number; // between 0 and 100
} & (
  | {
      discountType: 'global';
    }
  | {
      discountType: 'persona';
      additionalDiscount: number; // between 0 and 100
    }
  | {
      discountType: 'collection';
      additionalDiscount: number; // between 0 and 100
      collectionDiscount: number; // between 0 and 100
    }
);

// formula from https://andisor.atlassian.net/wiki/spaces/ANDISOR/pages/7340033/Pricing
export const getWHS = (props: getWHSProps) => {
  const { discountType, RRP, discount } = props;

  let whs = 0;
  const roundedGstAdjustedRRP = Math.round((RRP / 1.1 + Number.EPSILON) * 100) / 100;

  // 1.1 because 0.1 is for GST
  switch (discountType) {
    case 'global': {
      // is equal to Wholesale Price row in table from above link
      whs = roundedGstAdjustedRRP * (1 - discount / 100);
      break;
    }
    case 'persona': {
      // is equal to Persona Wholesale Price row in table from above link
      whs = roundedGstAdjustedRRP * (1 - discount / 100) * (1 - props.additionalDiscount / 100);
      break;
    }
    case 'collection': {
      // is equal to Net Wholesale Price row in table from above link
      whs =
        roundedGstAdjustedRRP *
        (1 - discount / 100) *
        (1 - props.additionalDiscount / 100) *
        (1 - props.collectionDiscount / 100);
      break;
    }
  }

  return whs;
};

export const getDeliveryWindowDates = ({ start, end }: { start: Date; end: Date }) => {
  const today = startOfToday();

  // gets all the 15th and 30th dates of a month, within delivery window for the discount
  const allDaysWithinDeliveryWindow: Date[] = [];

  // when start date is after end date, it is invalid, return no dates
  if (compareDesc(start, end) === -1) {
    return [];
  }

  const deliveryWindowDates = eachDayOfInterval({
    start,
    end,
  });

  deliveryWindowDates.forEach(day => {
    // if day is before today, dont add day to allDaysWithinDeliveryWindow array
    if (compareDesc(day, today) === 1) {
      return;
    }

    const daysInThisMonth = getDaysInMonth(day);

    const dateOfDayAsNumber = getDate(day);

    if (dateOfDayAsNumber === 15) {
      allDaysWithinDeliveryWindow.push(day);
      return;
    }

    // handling for february having 28 days or 29 days in leap years
    if (daysInThisMonth < 30 && isLastDayOfMonth(day)) {
      allDaysWithinDeliveryWindow.push(day);
      return;
    }

    if (dateOfDayAsNumber === 30) {
      allDaysWithinDeliveryWindow.push(day);
    }
  });

  // when allDaysWithinDeliveryWindow is empty, add the last day in the delivery window, === end date
  if (allDaysWithinDeliveryWindow.length === 0) {
    allDaysWithinDeliveryWindow.push(startOfDay(end));
  }

  return allDaysWithinDeliveryWindow;
};

export const getGrossMargin = ({ RRP, WHS }: { RRP: number; WHS: number }) => {
  return ((RRP - WHS) / RRP) * 100;
};

export const calculateGSTAmount = (subTotalAmount: number) => {
  if (Number.isNaN(subTotalAmount)) return undefined;

  return (subTotalAmount * GST_PERCENT) / 100;
};

export const calculateTotal = (subTotalAmount: number, gstAmount: number) => {
  if (Number.isNaN(subTotalAmount) || Number.isNaN(gstAmount)) return undefined;

  return subTotalAmount + gstAmount;
};

export const isOutOfOrderWindow = (orderWindow: { startDate: Date; endDate: Date }): boolean => {
  if (orderWindow?.startDate && orderWindow?.endDate) {
    const now = new Date();
    const startDate = startOfDay(orderWindow.startDate);
    const endDate = endOfDay(orderWindow.endDate);

    return !isWithinInterval(now, { start: startDate, end: endDate });
  }
  return false;
};

export const calculateRecommendedUnits = (params: {
  plannedSales: string;
  currentStock: string;
  sellingPeriod: string;
  closingInventory: string;
  returnRate: string;
}) => {
  // formmula:
  // recommendedUnits = plannedSales * (1 + returnRate) * sellingPeriod +
  // 	plannedSales * closingInventory -
  // 	currentStock;
  const plannedSales = convertStringToNumber(params.plannedSales);
  const currentStock = convertStringToNumber(params.currentStock);
  const sellingPeriod = convertStringToNumber(params.sellingPeriod);
  const closingInventory = convertStringToNumber(params.closingInventory);
  let returnRate = convertStringToNumber(params.returnRate, 'float');

  returnRate = (Number.isNaN(returnRate) ? 0 : returnRate) / 100;

  const recommendedUnits =
    plannedSales * (1 + returnRate) * sellingPeriod +
    plannedSales * closingInventory -
    currentStock;

  return Math.round(recommendedUnits);
};
