import { memo } from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { IconAlertTriangle, IconPencil } from '@tabler/icons-react';
import { useFormState } from 'react-hook-form';
import { Tooltip, UnstyledButton } from '@ui/core';
import { cx } from '@ui/utils';
import { type RowData } from '../../types';
import { type ProductDataGridFormType } from '../../useProductDataGridForm';

const StoreQuantityCell = memo((props: CustomCellRendererProps<RowData>) => {
  const isSecondaryVariantLevel = props.data?.type === 'secondaryVariantRow';
  const addressId = props.colDef?.cellEditorParams.addressId;

  const { errors } = useFormState<ProductDataGridFormType>({
    name: `brands.brand_${props.data?.brandId}.primaryVariants.${props.data?.parentUniqueRowId}.secondaryVariants.${props.data?.uniqueRowId}.storeToQuantityMap.${addressId}`,
    exact: true,
  });

  const showEditor = (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    props.api.startEditingCell({
      rowIndex: props.node.rowIndex as number,
      colKey: props.colDef?.field as string,
      key: props.node.key as string,
    });
  };

  const error =
    errors?.brands?.[`brand_${props.data?.brandId}`]?.primaryVariants?.[
      props.data?.parentUniqueRowId ?? ''
    ]?.secondaryVariants?.[props.data?.uniqueRowId ?? '']?.storeToQuantityMap?.[addressId];

  return isSecondaryVariantLevel ? (
    <Tooltip
      label={error ? error.message : props.value}
      visible={Boolean(props.value !== undefined || error)}
      className={cx(
        'rounded-none bg-andisor-navy px-1 py-[0.5px] font-system !text-[11px] font-semibold',
        error && 'bg-andisor-red'
      )}
      withinPortal
      position="left"
      openDelay={300}
    >
      <div
        className={cx(
          'mx-0.5 flex h-[27px] items-center justify-between gap-2 truncate border-b-2 border-l-0 border-r-0 border-t-0 border-andisor-blue bg-andisor-blue-50/60 px-2',
          error && 'mx-0 border border-andisor-red text-andisor-red'
        )}
      >
        <UnstyledButton onClick={showEditor}>
          {error ? (
            <IconAlertTriangle size={14} className="shrink-0 text-andisor-red" />
          ) : (
            <IconPencil size={14} className="shrink-0 text-andisor-navy" stroke={1.6} />
          )}
        </UnstyledButton>
        <span className="truncate text-right">{props.value}</span>
      </div>
    </Tooltip>
  ) : (
    <Tooltip
      label={props.value}
      visible={Boolean(props.value !== undefined)}
      className="rounded-none bg-andisor-navy px-1 py-[0.5px] font-system !text-[11px] font-semibold"
      withinPortal
      position="left"
      openDelay={300}
    >
      <div className="mx-0.5 h-[27px] px-2">
        <div>{props.value}</div>
      </div>
    </Tooltip>
  );
});

export default StoreQuantityCell;
