import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { CustomCellEditorProps } from '@ag-grid-community/react';
import { IconInfoCircle } from '@tabler/icons-react';
import { addDays, format, parse, parseISO, startOfToday } from 'date-fns';
import { DatePickerInput, Select, SelectProps, Text } from '@ui/core';
import { shouldExcludeDate } from '@components/ProductDataGrid/utils';
import { type RowData } from '../../types';
import { useProductDataGridFormContext } from '../../useProductDataGridForm';

export const dateFormatString = 'dd-MMM-yyyy';
const today = startOfToday();
export const defaultDeliveryWindow = {
  deliveryStartDate: today,
  deliveryEndDate: addDays(today, 90),
};

const classNames: SelectProps['classNames'] = {
  wrapper: 'px-0',
  input: 'text-xs',
  option: 'text-xs w-full',
};

const DeliveryDateCellEditor = memo(
  ({ value, onValueChange, stopEditing, data }: CustomCellEditorProps<RowData>) => {
    const refContainer = useRef<HTMLDivElement>(null);

    const { getValues } = useProductDataGridFormContext();

    useEffect(() => {
      refContainer.current?.focus();
    }, []);

    const values = {
      type: data.type,
      brandId: data.brandId,
      uniqueRowId: data.uniqueRowId,
      collections: data.type === 'primaryVariantRow' ? data.collections : undefined,
      appliedCollectionId: data.type === 'primaryVariantRow' ? data.appliedCollectionId : undefined,
      indentOnly: data.type === 'primaryVariantRow' ? data.indentOnly : undefined,
    };

    const pricingOptionSelected = useMemo(() => {
      const collection = values.collections?.find(
        option => option.id === values.appliedCollectionId
      );
      // must select a collection if indent only
      return !(values.indentOnly && !collection?.id);
    }, [values.appliedCollectionId, values.collections, values.indentOnly]);

    const deliveryWindowDates = useMemo(() => {
      if (
        pricingOptionSelected &&
        values.type === 'primaryVariantRow' &&
        values.collections &&
        values.appliedCollectionId !== undefined
      ) {
        const deliveryWindows = getValues(
          `brands.brand_${values.brandId}.primaryVariants.${values.uniqueRowId}.deliveryWindows`
        );
        const dates = deliveryWindows[values.appliedCollectionId ?? 'default'];
        return dates.map(item => format(item, dateFormatString));
      }
      return [];
    }, [
      pricingOptionSelected,
      values.type,
      values.collections,
      values.appliedCollectionId,
      values.brandId,
      values.uniqueRowId,
      getValues,
    ]);

    const selectedCollection = useMemo(() => {
      if (values.type === 'primaryVariantRow' && values.collections) {
        return values.collections.find(option => option.id === values.appliedCollectionId);
      }
    }, [values.appliedCollectionId, values.collections, values.type]);

    const inSeasonCollectionSelected = selectedCollection?.type === 'in-season';

    const onChange = useCallback(
      (selectedDate: string | null) => {
        if (selectedDate) {
          const dateISOString = parse(selectedDate, dateFormatString, new Date()).toISOString();
          onValueChange(dateISOString);
          stopEditing();
        }
      },
      [onValueChange, stopEditing]
    );

    return (
      <div ref={refContainer} className="border border-andisor-blue bg-white p-2">
        <Text className="mb-2 flex w-52 items-center gap-2 text-xs">
          <IconInfoCircle size={16} stroke={1.5} className="shrink-0" />
          {deliveryWindowDates.length > 0 || inSeasonCollectionSelected
            ? 'Choose your preferred delivery date. Suppliers will aim to deliver before this date.'
            : 'Select a pricing option to view delivery dates'}
        </Text>
        {inSeasonCollectionSelected ? (
          <DatePickerInput
            value={value ? parseISO(value) : undefined}
            minDate={startOfToday()}
            onChange={date => onChange(date ? format(date, dateFormatString) : null)}
            placeholder={!value ? 'Select' : undefined}
            size="xs"
            excludeDate={(date: Date) => {
              return shouldExcludeDate(date);
            }}
          />
        ) : (
          <Select
            classNames={classNames}
            size="xs"
            maxDropdownHeight={200}
            checkIconPosition="right"
            placeholder={!value ? 'Select' : undefined}
            data={deliveryWindowDates}
            disabled={deliveryWindowDates.length === 0}
            value={value ? format(parseISO(value), dateFormatString) : ''}
            onChange={onChange}
          />
        )}
      </div>
    );
  }
);

export default DeliveryDateCellEditor;
